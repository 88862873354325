import React from "react"
import { PrivacyPolicy } from "@tyson-foods/react-component-library"
import Layout from "../components/Layout/Layout"
import "./legal.scss"
import { Link } from "gatsby"
import './privacy-policy.scss';

const Privacy = (props) => {
  return (
    <Layout
      metaTitle="Privacy Policy"
      metaDescription="This Privacy Policy describes rights and rules on how we carefully treat your personal information on the websites, apps, and platforms of where it is located."
    >
      <div className="privacyPolicyPage mt-4 mb-4">
        <PrivacyPolicy
          className="p-3"
          aboutOurAdsLink={<Link to="/about-our-ads/">HERE</Link>}
        />
      </div>
    </Layout>
  );
}

export default Privacy;
